.DetailLabel {
    font-size: 13px;
    color: #000000;
}

.DetailData {
    font-size: 13px;
    color: #A8A9AF;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc( 100% - 30px );
}