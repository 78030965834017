.Pulse {
  position: absolute;
  width: 100%;
  //height: 100%;
  overflow: visible;
}

.tableOptionButton {
  color: #1498D8;
  padding: 0 0 0 0;
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 0;
  &:hover {
    background-color: #FFFFFF;
    color: #021EBB;
  }

  &:active {
    background-color: #FFFFFF;
    color: #021EBB
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FFFFFF;
  }
}

.whiteStandardButton {
  color: #FFFFFF;
  margin: 0 0 0 0;
  padding: 0 20px 0 20px;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 100px;
  border-color: #1498D8;
  background-color: #FFFFFF;
  height: 40px;
  width: auto;
  min-width: 0;

  &:hover {
    background-color: #FFFFFF;
    color: #FFFFFF;
  }

  &:active {
    background-color: #FFFFFF;
    color: #FFFFFF
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FFFFFF;
  }
}

.blueStandardButton {
  color: #FFFFFF;
  margin: 0 0 0 0;
  padding: 0 20px 0 20px;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 100px;
  border-color: #1498D8;
  background-color: #1498D8;
  height: 40px;
  width: auto;
  min-width: 100px;

  &:hover {
    background-color: #004E75;
    color: #FFFFFF;
  }

  &:active {
    background-color: #004E75;
    color: #FFFFFF
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FFFFFF;
  }
}

.redStandardButton {
  color: #FFFFFF;
  margin: 0 0 0 0;
  padding: 0 20px 0 20px;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 100px;
  border-color: #BD2930;
  background-color: #BD2930;
  height: 40px;
  width: auto;
  min-width: 100px;

  &:hover {
    background-color: #730E03;
    color: #FFFFFF;
  }

  &:active {
    background-color: #730E03;
    color: #FFFFFF
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FFFFFF;
  }
}

.blueOutlinedButton {
  color: #1498D8;
  margin: 0 0 0 0;
  padding: 0 20px 0 20px;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 100px;
  border-color: #1498D8;
  background-color: #ffffff;
  height: 40px;
  width: auto;
  min-width: 0;

  &:hover {
    background-color: #EDEFF3;
    border-color: #021EBB;
    color: #1498D8;
  }

  &:active {
    background-color: #757575;
    border-color: #021EBB;
    color: #1498D8;
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.blueIconButton {
  color: #1498D8;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #FFFFFF;
  height: 30px;
  min-width: 30px;

  &:hover {
    background-color: #FFFFFF;
    border-radius: 5px;
    color: #021EBB;
  }

  &:active {
    background-color: #ffffff;
    border-radius: 5px;
    color: #021EBB
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.blueIconButton2 {
  color: #FFFFFF;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #1498D8;
  height: 30px;
  min-width: 30px;

  &:hover {
    background-color: #1498D8;
    border-radius: 5px;
    color: #021EBB;
  }

  &:active {
    background-color: #FFFFFF;
    border-radius: 5px;
    color: #021EBB
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FFFFFF;
  }
}